import React, { useEffect, useState, useContext,useRef } from "react";
import { Avatar } from "../../constant/images";
import { Image, Row, Col, Popover, Modal, Upload, Button, Drawer, Spin,Card } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import * as updateServices from "../../services/updateServices";
import Sidebar from "../Sidebar/sidebar";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import * as getServices from "../../services/getServices";
import { useNavigate } from "react-router-dom";
import { CgMenuLeft } from "react-icons/cg";
import Notifications from "react-notifications-menu";
import { BsCart3 } from "react-icons/bs";
import { useAtom } from 'jotai';

// import {
//     setUserData,
//     setUserLoggedIn,
//     setVendorLoggedIn,
//     setToken
// } from "../../store/actions/user";
import {
    setUserData, setUserLoggedIn,
    setVendorLoggedIn,
    setToken
} from "../../StoreRedux/appSlice";
import {
    CloseOutlined,
} from '@ant-design/icons';
import { FaFileUpload } from "react-icons/fa";
import { FaUser } from "react-icons/fa";
import { RiLockPasswordFill } from "react-icons/ri";
import { IoLogOut } from "react-icons/io5";
import { BellIcon, Logo } from "../../constant/images"
import { DEMO_IMAGE_URL } from "../../Helper"
import { CartContext } from "../../Helpers/CartContext";
import { cartCount } from "../../Helpers/atoms/freeCoursesAtom";
import { TbChevronsDownLeft } from "react-icons/tb";


const Index = () => {

    const { cart } = useContext(CartContext);

    const [cartCountValue, setCartCountValue] = useAtom(cartCount);
    const [cartData, setCartData] = useState(0);
    const cartItems = useSelector((state) => state.user?.cart);
    const { addItemToCart } = useContext(CartContext);
    const inputRef = useRef(null);
     
    const fetchCartItems = async () => {
        try {
            const response = await dispatch(getServices.getCartData());
            addItemToCart(response?.data?.length);
            setCartCountValue(response?.data?.length || 0)
            setCartData(response?.data || []);
        } catch (error) {
            console.error("Error fetching cart data", error);
        }

    };
  
    useEffect(() => {
        if (isUserLoggedIn) {
            fetchCartItems();
        }
    }, [cartItems]);
    const navigate = useNavigate();
    const vendordata = useSelector((state) => state.user.userData);
    const notificationData = useSelector((state) => state.user.notificationData);
    const isUserLoggedIn = useSelector((state) => state.user.isUserLoggedIn);
    const dispatch = useDispatch();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [fileList, setFileList] = useState([]);
    const [open, setOpen] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const [previousModel, setPreviousModel] = useState(false)
    const [previousData , setPreviousData] = useState([])
    const logo = useSelector((state) => state?.user?.logoData);
    const [loading, setLoading] = useState(false);
    const [visibleCount, setVisibleCount] = useState(5);
    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };
    const handleChange = ({ fileList: newFileList }) => {
        setFileList(newFileList);
        if (newFileList[0]?.status === "error" || newFileList[0]?.status == "success") {

            onFinish(newFileList);
        }
    };
    const handleCart = () => {
        navigate("/cart");

    }

    const getNotifications = () => {

        dispatch(getServices.getNotifications())
            .then((response) => {
                
                if (response?.data?.length > 0) {

                    setNotifications(response?.data);
                } else {

                    setNotifications([]);
                }
            })
            .catch((err) => {
                console.error({ err });
            });
    }
    useEffect(() => {
        getNotifications()
    }, []);


    const DateFormatter = (date) => {
        const inputDate = date;

        const dateObj = new Date(inputDate);

        const monthNames = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ];

        const day = dateObj.getDate();
        const month = monthNames[dateObj.getMonth()];
        const year = dateObj.getFullYear();

        const formatDate = `${day} ${month} ${year}`;
        return formatDate;
    };

    const uploadButton = (
        <div>
            <div className="upload-icon-container">
                <FaFileUpload />
            </div>
            <div className="upload-drag-text" style={{ marginTop: 8 }}>Upload aur drag image</div>
        </div>
    );

    const isVendorLoggedIn = useSelector((state) => state.user.isVendorLoggedIn);

    const handleCancel = () => {

        setIsModalOpen(false);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const showModal = () => {
        if (isVendorLoggedIn) {
            navigate('/vendor/my-profile')
        } else {
            navigate('/user/my-profile')

        }

    };

    const onFinish = (newFileList) => {
        if (!newFileList.length) {
            return;
        }

        const formData = new FormData();
        const userId = vendordata?.user?.id;

        if (!userId) {
            return;
        }
        formData.append("images", newFileList[0]?.originFileObj);

        dispatch(updateServices.updateProfileImage(formData))
            .then(() => {
                setIsModalOpen(false)
                Swal.fire(
                    "Profile Updated Successfully!",
                    "You have successfully updated your profile",
                    "success"
                ).then(() => {
                    if (isVendorLoggedIn) {
                        dispatch(getServices.getVendorDetails(userId))
                            .then((response) => {
                                dispatch(setUserData(response));
                                window.location.reload();
                            })
                            .catch((error) => {
                                console.error("Error updating profile image:", error);
                            });
                    } else {
                        dispatch(getServices.getUserDetails(userId))
                            .then((response) => {
                                dispatch(setUserData(response));
                                window.location.reload();
                            })
                            .catch((error) => {
                                console.error("Error updating profile image:", error);
                            });
                    }
                });
            })
            .catch((error) => {
                setIsModalOpen(false)
                // console.error("Error updating profile image:", error);

                Swal.fire("Failed", error?.response?.data?.message, "erroe");
            });
    };

    const handleLoggout = () => {
        dispatch(setToken(null));
        dispatch(setVendorLoggedIn(false));
        dispatch(setUserLoggedIn(false));
        dispatch(setUserData(""));
        localStorage.clear()
        navigate("/login")
    };

    const renderContent = () => (
        <>
            <div className="edit-profile-container">
                <p className="edit-profile" onClick={showModal}>
                    <span><FaUser /></span>Edit Profile
                </p>
                {isVendorLoggedIn ? (
                    <p className="edit-profile" onClick={() => navigate("/vendor/change-password")}>
                        <span><RiLockPasswordFill /></span> Change Password
                    </p>
                ) : ("")}

                <p className="logout-button" onClick={handleLoggout}>
                    <span><IoLogOut /></span>Logout
                </p>
            </div>
        </>
    );
    const handleNotificationClick = async (idOrValue) => {
        try {
            setLoading(true);
            const payload =
                typeof idOrValue === "boolean"
                    ? { previous: idOrValue }
                    : { notification_id: idOrValue };

            const response = await dispatch(updateServices.updateNotificationStatus(payload));
            if (response.message) {
                await getNotifications();
            } else {
                setPreviousData(response?.data || []);
            }
        } catch (error) {
            console.error("Error updating notification status:", error);
        } finally {
            setLoading(false);
        }
    };

    const updatedNotifications = ( notifications )?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))?.map(notification => {


        return {
            key: notification.id,
            message: (
                <div className="notification-message-container" key={notification?.id} onClick={() => handleNotificationClick(notification.id)} >
                    <div className="notification-title-time" key={notification?.id}>
                        <p className="notification-title" key={notification?.id} >
                            {notification?.title}
                        </p>
                        <p className="notification-time">{DateFormatter(notification?.created_at)}</p>
                    </div>
                    <div className="notification-message-container">
                        <p className="notification-message" >
                            {notification?.message}
                        </p>
                    </div>
                </div>
            ),
            isRead: notification.isRead,
        };
    });
    const handleShowMore = () => {
        setVisibleCount((prevCount) => prevCount + 10);
    };


    const handleShowLess = () => {
        setVisibleCount(10);
    };


    const visibleNotifications = previousData
        ?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
        ?.slice(0, visibleCount);


    const handlemodel = () => {
        let previousValue = true;
        handleNotificationClick(previousValue)
        setPreviousModel(true)
    }
    const handlePreviousOk = () => {
        setPreviousModel(false)
    }

  
    return (
        <>
            <div className="topNavContainer">
                <Row className="top-nav-row">
                    <Col className="top-nav-col-one" md={4}>
                        <Image
                            style={{ cursor: "pointer" }}
                            onClick={() => navigate("/")}
                            preview={false}
                            className="well-of-logo-image"
                            src={logo?.front_logo ? `${DEMO_IMAGE_URL}${logo?.front_logo}` : Logo}
                        />
                    </Col>
                    <Col className="top-nav-col-two justify-content-end" md={20} >
                        <div className="align-items-center d-flex position-relative h-100 justify-content-end">
                            <div style={{ marginRight: "1rem" }}>
                                <Button onClick={(e) => navigate("/")} style={{ backgroundColor: "#106ab3", color: "#fff" }} className="topNav_back_btn"> Back to home</Button>
                            </div>

                            <div className="flag-icon-profile-container">
                                <Notifications
                                    data={updatedNotifications}
                                    key={updatedNotifications?.key}
                                    header={{
                                        option: {
//                                             text: (
//                                                 <div className="mark-all-container">
//                                                     < button
//                                                         className="mark-all-checkbox"
// onClick={handlemodel}
                                                       
//                                                     >
//                                                         <span className="mark-all-text"> Previous</span>
//                                                     </button>
//                                                 </div>
                                            //                                             ),
                                            text: 'Show Previous', onClick: handlemodel
                                        },
                                    }}
                                    icon={BellIcon}
                                />
                                {isUserLoggedIn ? (
                                    <>
                                        <div className="bellIconWrapper user_cart_item cart-header-icon" style={{ paddingLeft: "5px", marginRight: "18px" }}>
                                            {/* <span className="add-to-cart-count">{cartCountData}</span> */}
                                            <span className="add-to-cart-count 0000">{cart !== undefined ? cart : 0}</span>
                                            <BsCart3 onClick={handleCart} className="bell-icon" color="#106AB3" />
                                        </div>
                                    </>
                                ) : ''}
                                <div className="profile-container">
                                    <Popover
                                        content={renderContent()}
                                        title=""
                                        trigger="click"
                                        placement="bottomRight"
                                    >
                                        <div className="profile-image-name-container">
                                            <Image
                                                style={{ cursor: "pointer", objectFit: "cover" }}
                                                preview={false}
                                                width={47}
                                                height={47}
                                                src={
                                                    vendordata?.user?.images
                                                        ? vendordata?.user?.images?.includes("profile_images/") ? `${DEMO_IMAGE_URL}${vendordata?.user?.images}` : `${vendordata?.user?.images}`
                                                        : vendordata?.vendor?.logo
                                                            ? `${DEMO_IMAGE_URL}${vendordata?.vendor?.logo}`
                                                            : Avatar
                                                }
                                            />
                                            <p
                                                style={{ cursor: "pointer" }}
                                                className="owner-name"
                                            >
                                                {vendordata?.user?.name}
                                            </p>

                                        </div>
                                    </Popover>


                                </div>
                                <Button className="d-xl-none d-md-block menu-bar-icon" type="primary" onClick={showDrawer}>
                                    <CgMenuLeft />
                                </Button>
                                <Drawer className="mobile-menu-dasboard" title="Menu" onClose={onClose} open={open} placement={"left"} key={"left"} width={375}>
                                    <Sidebar />
                                </Drawer>
                            </div>


                        </div>
                    </Col>
                </Row>
            </div>
            <div className="modalWrapper">
                <Modal
                    open={isModalOpen}
                    onOk={handleOk}
                    className="imgUploadModal"
                >
                    <div className="modalCloseBtn" onClick={handleCancel}>
                        <CloseOutlined />
                    </div>
                    <div className="modalBody">
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginBottom: "1rem",
                            }}
                        >
                            <Upload
                                action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                                listType="picture-card"
                                fileList={fileList}
                                onChange={handleChange}
                            >
                                {fileList.length >= 1 ? null : uploadButton}
                            </Upload>
                        </div>
                    </div>
                </Modal>
            </div>
            <Modal title="Notificaton" open={previousModel} onOk={handlePreviousOk} onCancel={handlePreviousOk} footer={false}>
               
                {loading ? (
                    <div className="d-flex justify-content-center align-items-center mt-4 mb-4">
                        <Spin
                            indicator={
                                <LoadingOutlined
                                    style={{
                                        fontSize: 48,
                                    }}
                                    spin
                                />
                            }
                        />
                    </div>
                ) : (
                        <div>
                            {visibleNotifications?.length > 0 ? (
                                visibleNotifications.map((notification) => (
                                    <Card
                                        key={notification.id}
                                        className={`notification-card ${notification.isRead ? 'read' : 'unread'}`}
                                        hoverable
                                        onClick={() => handleNotificationClick(notification.id)}
                                    >
                                        <div className="notification-title-time">
                                            <p className="notification-title">{notification?.title}</p>
                                            <p className="notification-time">{DateFormatter(notification?.created_at)}</p>
                                        </div>
                                        <div className="notification-message-container">
                                            <p className="notification-message">{notification?.message}</p>
                                        </div>
                                    </Card>
                                ))
                            ) : (
                                <p>No notifications available</p>
                            )}
                            <div className="notification-buttons-container">
                                {/* Show More Button */}
                                {visibleCount < previousData?.length && (
                                    <Button type="primary" onClick={handleShowMore}>
                                        Show More
                                    </Button>
                                )}

                                {/* Show Less Button */}
                                {visibleCount > 5 && (
                                    <Button type="default" onClick={handleShowLess} style={{ marginLeft: '10px' }}>
                                        Show Less
                                    </Button>
                                )}
                            </div>
                            </div>
                )
                }
            </Modal>
        </>
    );
};

export default Index;

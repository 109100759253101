import React from 'react'
import { Row, Col, Table, Button } from 'antd'
import { RiDeleteBin6Line } from "react-icons/ri";

const WishList = () => {

  const columns = [
    {
      title: "Item Name",
      dataIndex: "title",
      key: "title",
     
    },
    {
      title: "About item",
      dataIndex: "about_course",
      key: "about_course",
    
    },
    {
      title: "Fees",
      dataIndex: "fee",
      key: "fee",
    
    },
    {
      title: "Expired On",
      dataIndex: "course_end",
      key: "course_end",
    
    },
    {
      title: "Instructor Name",
      dataIndex: "instructor_name",
      key: "instructor_name",
    
    },
    {
      title: "Instructor Experience",
      dataIndex: "work_experience",
      key: "work_experience",
    
    },
    {
      title: "Actions",
      dataIndex: "Actions",
      key: "Actions",
      render: (text, record) => (
        <>
          <Button className="delete-button"><RiDeleteBin6Line color="#DA0734" /></Button>
        </>
      ),
    },
  ];
    return (
        <div>
        This is wishlist page 
        <Row>
          <Col md={24}>
            <Table
              className="table"
        
              columns={columns}
              // dataSource={data}
            />
          </Col>
        </Row>
        </div>
    )
}

export default WishList
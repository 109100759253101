
import React, { useState, useMemo, useEffect } from 'react';
import Sidebar from "../../../components/Sidebar/sidebar";
import { useSelector } from "react-redux";
import { Row, Col, DatePicker, Button, Select, Table, Pagination, Spin } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import { useDispatch } from "react-redux";
import * as getServices from "../../../services/getServices";
export default function MyTransactions() {
    const dispatch = useDispatch();
    const [categoryId, setCategoryId] = useState(null);
    const [orderStatus, setOrderStatus] = useState(null);
    const [sorting, setSorting] = useState(null);
    const [selectedDates, setSelectedDates] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [datePickerValue, setDatePickerValue] = useState([]);
    const [pageSize, setPageSize] = useState(5);
    const [loader, setLoader] = useState(5);
    const [totalCustomers, setTotalCustomers] = useState(0);
    const [customers, setCustomers] = useState([]);

    const categories = useSelector((state) => state.user.categoriesData);

    const categoryList = useMemo(() =>
        categories?.map((item) => ({
            label: item.name,
            value: item.id,
        })),
        [categories]);

    const onFilter = (date, dateString) => {
        setDatePickerValue(date)
        setSelectedDates(dateString)
    }

    const fetchUserCourses = (currentPage, selectedDates) => {
        setLoader(true)
        let queryString = `?my_course=true&per_page=${pageSize}&page=${currentPage}`
        if (categoryId) {
            queryString += `&category_id=${categoryId}`
        }

        if (sorting) {
            queryString += `&sort_by=${sorting}`
        }

        if (orderStatus) {
            queryString += `&status=${orderStatus}`
        }

        if (selectedDates?.length > 0) {
            queryString += `&start_date=${selectedDates[0]}&end_date=${selectedDates[1]}`
        }
        dispatch(getServices?.getUserCourses(queryString)).then((response) => {
            setTotalCustomers(response?.meta?.total)
            setLoader(false);
            setCustomers(response);
        }).catch((error) => {
            setLoader(false)
            console.error(error)
        })
    }

    const handleReset = () => {
        setSelectedDates([])
        setDatePickerValue([]);
        fetchUserCourses(1)

    }
    useEffect(() => {
        fetchUserCourses(currentPage, selectedDates)
    }, [categoryId, orderStatus, sorting, currentPage, pageSize])

    const handlePageSizeChange = (current, size) => {
        setPageSize(size);
        setCurrentPage(1);
    };

    const handleChangePage = (page) => {
        setCurrentPage(page);
    };

    const generatePageSizeOptions = (total) => {
        const options = [];
        const maxPageSize = Math.min(total, 100);

        for (let i = 5; i <= maxPageSize; i += 5) {
            options.push(i);
        }
        if (total > 5 && !options.includes(10)) {
            options.push(10);
        }

        if (total > 10 && !options.includes(15)) {
            options.push(15);
        }
        return options.sort((a, b) => a - b);
    };

    const pageSizeOptions = generatePageSizeOptions(totalCustomers);

    const orderStatusList = [
        {
            key: '1',
            label: 'Completed',
            value: '2'
        },
        {
            key: '2',
            label: 'Pending',
            value: '0'
        },
        {
            key: '3',
            label: 'Canceled',
            value: '1'
        }
    ];

    const sortingList = [
        {
            key: 'desc',
            label: 'Newest to oldest',
            value: 'desc'
        },
        {
            key: 'asc',
            label: 'Oldest to newest',
            value: 'asc'
        },
    ];

    const DateFormatter = (date) => {
        const inputDate = date;
        const dateObj = new Date(inputDate);
        const monthNames = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ];

        const day = dateObj.getDate();
        const month = monthNames[dateObj.getMonth()];
        const year = dateObj.getFullYear();

        const formatDate = `${day} ${month} ${year}`;
        return formatDate;
    };

    const columns = [
        {
            title: "S. No.",
            dataIndex: 'serial',
            width: "5%",
            render: (_, record, index) => (currentPage - 1) * pageSize + index + 1,
        },
        {
            title: "Course Name",
            dataIndex: "Course Name",
            key: "course_name",
            render: (text, record) => (
                <p style={{ fontSize: "13px" }} className="course-title">{record?.title}</p>
            ),
        },
        {
            title: "Transaction Id",
            dataIndex: "Transaction Id",
            key: "transaction_id",
            render: (text, record) => (
                <p style={{ fontSize: "13px" }} className="course-title">{record?.transaction_id !== null ? record?.transaction_id :''}</p>
            ),
        },
        {
            title: "Order Status",
            dataIndex: "Order Status",
            key: "order_status",
            render: (text, record) => (
                <p style={{ fontSize: "13px" }} className="course-title">{record?.cancelled_status === "" || record?.cancelled_status === null ? "completed" : record?.cancelled_status === "requested" ? "pending" : record?.cancelled_status === "approved" ? "cancelled" : "rejected"}</p>
            ),
        },
        {
            title: "Paid Amount",
            dataIndex: "Paid Amount",
            key: "paid_amount",
            render: (text, record) => (
                <p style={{ fontSize: "13px" }} className="course-title">{record?.amount_paid}</p>
            ),
        },
        {
            title: "Duration or Access Period",
            dataIndex: "Duration or Access Period",
            key: "duration_or_access_period",
            render: (text, record) => (
                <p style={{ fontSize: "12px" }} className="course-title">{record?.course_start && record?.course_end  !== null ? `${DateFormatter(record?.course_start)}   - ${DateFormatter(record?.course_end)}` : '-'}</p>
            ),
        },
    ];


    return (
        <Row>
            <Col xl={4} className="d-xl-block d-md-none">
                <Sidebar />
            </Col>
            {loader ? (
                <div className="loaderBackdrop">
                    <div className="loader">
                        <Spin indicator={<LoadingOutlined style={{ color: "#fff" }} spin />} />
                    </div>
                </div>
            ) : (
                    <Col xl={20} md={24}>
                        <div className="mainContent my-courses">
                            <div className="my-courses-container">
                                <div className="download-courses-container">
                                    <p className="my-course-heading">My Transactions</p>
                                    <p style={{ fontSize: "20px", fontWeight: "500", lineHeight: "150%" }} className="my-transations">Total Paid Amount - <span style={{ color: "#106AB3" }} className='total-transaction-amount'>${customers?.total_amount_paid ? customers?.total_amount_paid : 0}</span></p>

                                </div>
                                <div className="course-filter-add-item">
                                    <div className="courses-head-filter">
                                        <DatePicker.RangePicker
                                            onChange={onFilter}
                                            value={datePickerValue}
                                        />
                                        <Button onClick={() => fetchUserCourses(currentPage, selectedDates)}>Filter</Button>
                                        <Button onClick={handleReset} type="primary">Reset</Button>
                                    </div>
                                    <div className="filter-select-group">
                                        <Select
                                            className="total-students-enrolled-inner-select mx-3 select-category-dropdown"
                                            placeholder="Select Category"
                                            options={categoryList}
                                            onChange={(value) => setCategoryId(value)}
                                        />
                                        <Select
                                            className="total-students-enrolled-inner-select mx-3 select-oder-status-dropdown"
                                            placeholder="Select by order status"
                                            options={orderStatusList}
                                            onChange={(value) => setOrderStatus(value)}
                                        />
                                        <Select
                                            className="total-students-enrolled-inner-select ms-3 select-sort-dropdown"
                                            placeholder="Sort by..."
                                            options={sortingList}
                                            onChange={(value) => setSorting(value)}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <Table
                                        className="table table-responsive mt-4"
                                        columns={columns}
                                        dataSource={customers?.data}
                                        pagination={false}
                                       
                                    />
                                    <Pagination
                                        className="mt-4"
                                        current={currentPage}
                                        pageSize={pageSize}
                                        total={totalCustomers}
                                        onChange={handleChangePage}
                                        onShowSizeChange={handlePageSizeChange}
                                        showSizeChanger
                                        pageSizeOptions={pageSizeOptions}
                                    />
                                </div>
                            </div>
                        </div>

                    </Col>
            )}
         
        </Row>
    )
}


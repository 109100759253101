import React, { useEffect, useState } from 'react'
import Sidebar from '../../components/Sidebar/sidebar';
import { Row, Col, Table, Pagination, Spin, Button } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import * as getServices from "../../services/getServices";
import { useDispatch, useSelector } from "react-redux"

export default function CourseInquiries() {
    const dispatch = useDispatch();
    const vendordata = useSelector((state) => state.user.userData);
    const [pageSize, setPageSize] = useState(5);
    const [currentPage, setCurrentPage] = useState(1);
    const [loader, setLoader] = useState(false)
    const [enquiryData, setEnquiryData] = useState([])
    const [totalEnquiry, setTotalEnquiry] = useState(0)


    const DateFormatter = (date) => {
        const inputDate = date;
        const dateObj = new Date(inputDate);
        const monthNames = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ];

        const day = dateObj.getDate();
        const month = monthNames[dateObj.getMonth()];
        const year = dateObj.getFullYear();

        const formatDate = `${day} ${month} ${year}`;
        return formatDate;
    };
    const totalItems = enquiryData && enquiryData?.length > 0 ? enquiryData?.length : 0;
    const sortedData = enquiryData.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    const handlePageSizeChange = (current, size) => {
        setPageSize(size);
        setCurrentPage(1);
    };
    const generatePageSizeOptions = (total) => {
        const options = [];
        const maxPageSize = Math.min(total, 100);

        for (let i = 5; i <= maxPageSize; i += 5) {
            options.push(i);
        }
        if (total > 5 && !options.includes(10)) {
            options.push(10);
        }

        if (total > 10 && !options.includes(15)) {
            options.push(15);
        }
        return options.sort((a, b) => a - b);
    };
    const pageSizeOptions = generatePageSizeOptions(totalEnquiry);

    const columns = [

        {
            title: "S. No.",
            dataIndex: 'serial',
            render: (_, record, index) => (currentPage - 1) * pageSize + index + 1,
        },
        {
            title: "Student name",
            dataIndex: "name",
            key: "name",
            render: (text, record, index) => (
                <p className="student-name">
                    {record?.name}
                </p>
            ),
        },
        {
            title: "Student email",
            dataIndex: "email",
            key: "email",
        },
        {
            title: "Course name",
            dataIndex: "course-name",
            key: "course-name",
            render: (text, record, index) => (
                <p className="course-name">
                    {record?.course_name}
                </p>
            ),
        },
        {
            title: "Query subject",
            dataIndex: "type-of-enquiry",
            key: "type-of-enquiry",
            render: (text, record, index) => (
                <p className="type-of-enquiry">
                    {record?.typeOfEnquiry}
                </p>
            ),
        },
        {
            title: "Query message",
            dataIndex: "message",
            key: "message",
        },
        {
            title: "Date of submission",
            dataIndex: "date-of-submission",
            key: "date-of-submission",
            render: (text, record, index) => (
                <p className="date-of-submission">
                    {DateFormatter(record?.created_at)}
                </p>
            ),
        }
    ]

    const handleChangePage = (page) => {
        setCurrentPage(page);
      
    };


    const fetchEnquiries = () => {
        try {
            setLoader(true)
            let queryString;
            queryString = `?per_page=${pageSize}&page=${currentPage}`
            dispatch(getServices.getEnquiriesByVendorID(queryString)).then((response) => {
                setLoader(false)
                if (response?.contact?.data?.length > 0) {
                    setEnquiryData(response?.contact?.data)
                    setTotalEnquiry(response?.contact?.total)

                }
            }).catch((err) => {
                setLoader(false)
            })
        } catch (err) {
            console.error("Error fetching enquiries", err)
        }
    }
    useEffect(() => {
        fetchEnquiries()
    }, [currentPage, pageSize])
    const exportToExcel = (data, fileName) => {
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        const excelBuffer = XLSX.write(workbook, {
            bookType: "xlsx",
            type: "array",
        });
        const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
        saveAs(blob, `${fileName}.xlsx`);
    };

    const transformData = (data) => {
        return data.map((item, index) => ({
            "S.No.": index + 1,
            "Student name": item?.user?.name,
            "Student email": item?.user?.email,
            "Course name": item?.course_name,
            "Query subject": item?.typeOfEnquiry,
            "Query message": item?.message,
            "Date of submission": item?.created_at,
        }));
    };
    return (
        <>
            <Row>
                <Col xl={4} className="d-xl-block d-md-none">
                    <Sidebar />
                </Col>
                {loader ? (
                    <div className="loaderBackdrop">
                        <div className="loader">
                            <Spin indicator={<LoadingOutlined style={{ color: "#fff" }} spin />} />
                        </div>
                    </div>
                ) : (
                        <Col xl={20} md={24}>
                            <div className="mainContent course-inquiry">
                                <div className='all-enquiry-container'>
                                    <div className="download-enquiry-container">
                                        <p className='enquiry-heading total-transaction'>All Enquiries</p>
                                        <Button
                                            className="download-enquiry-button download-csv-button"
                                            onClick={() => exportToExcel(transformData(enquiryData), "Enquiries")}
                                        >

                                            Download Enquiries in xlsx/csv
                                            {/* {loader ? <Spin indicator={<LoadingOutlined style={{ color: "#fff" }} spin />} /> : "Download Enquiries in xlsx/csv"} */}
                                        </Button>
                                    </div>
                                    <div className='enquiry-header d-flex justify-content-between flex-wrap'>
                                        <p className='total-enquiries total-transaction-count'>Total Enquiries <span className='total-customer-number'>({totalEnquiry})</span></p>
                                    </div>


                                </div>

                                {/* {loader ? <Spin indicator={<LoadingOutlined style={{ color: "#fff" }} spin />} /> :    */}
                                {
                                    sortedData?.length > 0 ? (
                                        <>
                                            <div className="my-classes-table-container table-responsive">
                                                <Table
                                                    className="my-course-table"
                                                    columns={columns}
                                                    dataSource={sortedData}
                                                    // pagination={false}
                                                    pagination={{
                                                        showSizeChanger: true,
                                                        current: currentPage,
                                                        pageSize: pageSize,
                                                        total: totalEnquiry,
                                                        onChange: handleChangePage,
                                                        onShowSizeChange: handlePageSizeChange,
                                                        pageSizeOptions: pageSizeOptions,
                                                    }}


                                                />

                                            </div>
                                        </>
                                    ) : "No Data"
                                }

                            </div>
                        </Col>
                )}
               
            </Row>
        </>
    )
}

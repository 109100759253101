import React, { useEffect, useState } from "react";
import {
    Table,
    Button,
    Pagination,
    Row,
    Col,
    Modal,
    Input,
    Select,
    Form,
    Spin,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import Sidebar from "../../components/Sidebar/sidebar";
import { useNavigate } from "react-router-dom";
import * as uploadServices from "../../services/uploadServices";
import { useDispatch, useSelector } from "react-redux";
import { MdDelete } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import Swal from "sweetalert2";
import { MdOutlineCategory } from "react-icons/md";
import * as updateService from "../../services/updateServices";

export default function Classes() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { TextArea } = Input;
    const [currentPage, setCurrentPage] = useState(1);
    const [classData, setClassData] = useState([]);
    const vendorData = useSelector((state) => state.user.userData);
    const [pageSize, setPageSize] = useState(5);
    const [totalSession, setTotalSession] = useState(0);

    const [loader, setLoader] = useState(false);
    const [updatedLoader, setUpdateLoader] = useState(false);

    const [modalOpen, setModalOpen] = useState(false);
    const [editClassData, setEditClassData] = useState(null);

    const generatePageSizeOptions = (total) => {
        const options = [];
        const maxPageSize = Math.min(total, 100);

        for (let i = 5; i <= maxPageSize; i += 5) {
            options.push(i);
        }
        if (total > 5 && !options.includes(10)) {
            options.push(10);
        }

        if (total > 10 && !options.includes(15)) {
            options.push(15);
        }
        return options.sort((a, b) => a - b);
    };

    const pageSizeOptions = generatePageSizeOptions(totalSession);

    const handlePageSizeChange = (current, size) => {
        setPageSize(size);
        setCurrentPage(1);
    };

    const handleUpdate = (data) => {
        setEditClassData(data);
        setModalOpen(true);
    };

    const handleDelete = (data) => {
        Swal.fire({
            title: "Are you sure you want to delete this item?",
            text: "This action cannot be undone",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#d33",
            cancelButtonColor: "#3085d6",
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "Cancel",
        }).then((result) => {
            if (result.isConfirmed) {
                try {
                    dispatch(updateService.deleteClass(data?.id))
                        .then((response) => {
                            Swal.fire({
                                title: "Delete Item",
                                text: `${response?.message}`,
                                icon: "success",
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    handlegetclass();
                                }
                            });
                        })
                        .catch((err) => {
                            Swal.fire("Failed", `${err?.message}`, "error");
                        });
                } catch (err) {
                    Swal.fire("Failed", `Failed to detete items.`, "error");
                }
            }
        });
    };

    const columns = [
        {
            title: "S.No",
            dataIndex: "S.No",
            key: "S.No",
            render: (_, record, index) => (currentPage - 1) * pageSize + index + 1,
        },
        {
            title: "Name",
            key: "name",
            dataIndex: "name",
        },
        {
            title: "Max Size",
            dataIndex: "max_size",
            key: "max_size",
        },
        {
            title: "Min Size",
            dataIndex: "min_size",
            key: "min_size",
        },
        {
            title: "Availability",
            dataIndex: "class_size",
            key: "class_size",
        },
        {
            title: "Level",
            dataIndex: "class_level",
            key: "class_level",
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (status) => status === 0 ? 'Inactive' : 'Active'


        },
        {
            title: "Action",
            dataIndex: "action",
            key: "Action",
            render: (time, rec) => (
                <div className="timing">
                    <button
                        className="btn updateButton"
                        onClick={() => handleUpdate(rec)}
                    >
                        <FaEdit />
                    </button>
                    <button
                        className="btn deleteButton"
                        onClick={() => handleDelete(rec)}
                    >
                        <MdDelete />
                    </button>
                </div>
            ),
        },
    ];

    const handleClassButton = () => {
        navigate("/vendor/add-classes");
    };
    const handleClass = () => {
        setLoader(true);
        dispatch(uploadServices.getAllClasses(pageSize, currentPage)).then((res) => {
            setTotalSession(res?.classes?.total)
            setClassData(res?.classes?.data);
            setLoader(false);
        });
    }
    const handlegetclass = (page) => {
        setCurrentPage(page)

    };

    useEffect(() => {
        handleClass()
    }, [currentPage, pageSize]);

    const onFinish = (values) => {
        setUpdateLoader(true);

        if (vendorData?.vendor?.id) {
            const formData = new FormData();
            formData.append("name", values.class_name);
            formData.append("max_size", values.max_student_age);
            formData.append("min_size", values.min_student_age);
            formData.append("min_size", values.min_student_age);
            formData.append("class_size", values.class_size);
            formData.append("class_level", values.class_level);
            formData.append("description", values.class_description);
            formData.append("status", values.status);

            dispatch(updateService.updateClass(editClassData.id, formData))
                .then((res) => {
                    setUpdateLoader(false);
                    setModalOpen(false);

                    Swal.fire({
                        title: "Updated Item",
                        text: `${res.message}`,
                        icon: "success",
                    }).then((result) => {
                        if (result.isConfirmed) {
                            handlegetclass();
                        }
                    });
                })
                .catch((err) => {
                    setUpdateLoader(false);
                    Swal.fire("Error", `${err.message}`, "error");
                });
        } else {
            setUpdateLoader(false);
            Swal.fire("Login", "Please login to continue...", "info").then(
                (result) => {
                    navigate("/login");
                }
            );
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.error("Failed:", errorInfo);
    };




    return (
        <>
            <Row>
                <Col xl={4} className="d-xl-block d-md-none">
                    <Sidebar />
                </Col>
                {loader ? (
                    <div className="loaderBackdrop">
                        <div className="loader">
                            <Spin indicator={<LoadingOutlined style={{ color: "#fff" }} spin />} />
                        </div>
                    </div>
                ) : (
                    <Col xl={20} md={24}>
                    <div className="mainContent classes">
                        <div className="my-classes-container">
                            <div className="classes-head-container d-flex justify-content-between">
                                <p
                                    style={{
                                        fontSize: "26px",
                                        lineHeight: "31.2px",
                                        fontWeight: "600",
                                        fontFamily: "Poppins",
                                        margin: "2rem 0x",
                                    }}
                                    className="my-classes-heading"
                                >
                                    All Session
                                </p>
                                <Button
                                    onClick={handleClassButton}
                                    className="add-classes-button"
                                >
                                    Add Session
                                </Button>
                            </div>

                        
                                <div
                                    style={{ padding: "3rem 0" }}
                                    className="my-classes-table-container table-responsive"
                                >
                                    <Table
                                        className="my-classes-table"
                                        columns={columns}
                                        dataSource={classData}
                                        pagination={false}
                                        loading={{
                                            spinning: loader,
                                            indicator: <Spin indicator={<LoadingOutlined spin />} />,
                                        }}
                                    />

                                    <Pagination
                                        className="pagination mt-4"
                                        current={currentPage}
                                        onChange={handlegetclass}
                                        total={totalSession}
                                        pageSize={pageSize}
                                        onShowSizeChange={handlePageSizeChange}
                                        showSizeChanger
                                        pageSizeOptions={pageSizeOptions}
                                    />
                                    {/* <Pagination
                                            className="pagination mt-4"
                                            current={currentPage}
                                            pageSize={pageSize}
                                            total={totalSession}
                                            onChange={handleChangePage}
                                            // showSizeChanger={false}
                                            onShowSizeChange={handlePageSizeChange}
                                            showSizeChanger
                                            pageSizeOptions={pageSizeOptions}
                                        /> */}
                                </div>
                           
                        </div>
                    </div>
                </Col>)}
               
            </Row>

            <Modal
                title="Edit Session"
                centered
                open={modalOpen}
                // onOk={() => setModalOpen(false)}
                onCancel={() => setModalOpen(false)}
                className="update-class-modal"
                footer={null}
            >
                <div>
                    <Form
                        name="edit-form"
                        labelCol={{
                            span: 8,
                        }}
                        wrapperCol={{
                            span: 24,
                        }}
                        initialValues={{
                            class_name: editClassData?.name,
                            status: editClassData?.status,
                            class_size: editClassData?.class_size,
                            class_level: editClassData?.class_level,
                            min_student_age: editClassData?.min_size,
                            max_student_age: editClassData?.max_size,
                            class_description: editClassData?.description,
                        }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                        className="update-class-form"
                    >
                        <Row className="form-row">
                            <Col md={12} className="custom-padding">
                                <p
                                    style={{
                                        textAlign: "start",
                                        fontFamily: "POPPINS",
                                    }}
                                >
                                    Session Name
                                </p>
                                <Form.Item
                                    className="form-input-class"
                                    name="class_name"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please enter session name.",
                                        },
                                    ]}
                                >
                                    <Input
                                        className="input-box-login"
                                        placeholder="Session Name"
                                    />
                                </Form.Item>
                            </Col>
                            <Col md={12} className="custom-padding">
                                <p
                                    style={{
                                        textAlign: "start",
                                        fontFamily: "POPPINS",
                                    }}
                                >
                                    Status
                                </p>
                                <Form.Item
                                    className="form-input-class"
                                    name="status"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please select status",
                                        },
                                    ]}
                                >
                                    <Select
                                        className="input-box-login select-class-status"
                                        placeholder="Select Status"
                                        prefix={<MdOutlineCategory />}
                                    >
                                        <Select.Option value={1} key={"Active"}>
                                            Active
                                        </Select.Option>
                                        <Select.Option value={0} key={"In-Active"}>
                                            In Active
                                        </Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col md={12} className="custom-padding">
                                <p
                                    style={{
                                        textAlign: "start",
                                        fontFamily: "POPPINS",
                                    }}
                                >
                                    Student Allowed
                                </p>
                                <Form.Item
                                    className="form-input-class"
                                    name="class_size"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please select student allowed",
                                        },
                                    ]}
                                >
                                    <Select
                                        className="input-box-login select-class-size"
                                        placeholder="Select student allowed"
                                        prefix={<MdOutlineCategory />}
                                    >
                                        {[...Array(10).keys()].map((i) => (
                                            <Select.Option value={i + 1} key={i + 1}>
                                                {i + 1}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col md={12} className="custom-padding">
                                <p
                                    style={{
                                        textAlign: "start",
                                        fontFamily: "POPPINS",
                                    }}
                                >
                                    Level
                                </p>
                                <Form.Item
                                    className="form-input-class"
                                    name="class_level"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please select  level",
                                        },
                                    ]}
                                >
                                    <Select
                                        className="input-box-login select-class-level"
                                        placeholder="Select  Level"
                                        prefix={<MdOutlineCategory />}
                                    >
                                        <Select.Option value={"Basic"} key={"Basic"}>
                                            Basic
                                        </Select.Option>
                                        <Select.Option value={"Elementary"} key={"Elementary"}>
                                            Elementary
                                        </Select.Option>
                                        <Select.Option value={"Immediate"} key={"Immediate"}>
                                            Immediate
                                        </Select.Option>
                                        <Select.Option value={"Advanced"} key={"Advanced"}>
                                            Advanced
                                        </Select.Option>
                                        <Select.Option value={"Professional"} key={"Professional"}>
                                            Professional
                                        </Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col md={12} className="custom-padding">
                                <p
                                    style={{
                                        textAlign: "start",
                                        fontFamily: "POPPINS",
                                    }}
                                >
                                    Min Student Age
                                </p>
                                <Form.Item
                                    className="form-input-class"
                                    name="min_student_age"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please enter min age student.",
                                        },
                                    ]}
                                >
                                    <Input
                                        className="input-box-login"
                                        placeholder="Min Student Age"
                                        type="number"
                                    />
                                </Form.Item>
                            </Col>
                            <Col md={12} className="custom-padding">
                                <p
                                    style={{
                                        textAlign: "start",
                                        fontFamily: "POPPINS",
                                    }}
                                >
                                    Max Student Age
                                </p>
                                <Form.Item
                                    className="form-input-class"
                                    name="max_student_age"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please enter max age student.",
                                        },
                                    ]}
                                >
                                    <Input
                                        className="input-box-login"
                                        placeholder="Max Student Age"
                                        type="number"
                                    />
                                </Form.Item>
                            </Col>
                            <Col md={24} className="custom-padding">
                                <p
                                    style={{
                                        textAlign: "start",
                                        fontFamily: "POPPINS",
                                    }}
                                >
                                    Description
                                </p>
                                <Form.Item
                                    className="form-input-class"
                                    name="class_description"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please enter description.",
                                        },
                                    ]}
                                >
                                    <TextArea
                                        className="leave-comment-text-area"
                                        rows={4}
                                        placeholder="Type here....."
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Form.Item
                            className="mb-0 add-course-form-item"
                            wrapperCol={{
                                offset: 8,
                                span: 24,
                            }}
                        >
                            <Button className="register-button" htmlType="submit">
                                {updatedLoader ? (
                                    <div className="d-flex justify-content-center align-items-center mt-4 mb-4">
                                        <Spin
                                            indicator={
                                                <LoadingOutlined
                                                    style={{
                                                        fontSize: 28,
                                                    }}
                                                    spin
                                                />
                                            }
                                        />
                                    </div>
                                ) : (
                                    "Update Class"
                                )}
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
        </>
    );
}

import React, { useState, useEffect } from "react";
import {
    Button,
    Table,
    Popover,
    Modal,
    Row,
    Col,
    DatePicker,
    Spin,
    Pagination,
    Upload,
    Input,
    Space

} from "antd";
import { LiaClone } from "react-icons/lia";
import { LoadingOutlined } from '@ant-design/icons';
import Sidebar from "../../components/Sidebar/sidebar";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { IoEyeOutline } from "react-icons/io5";
import { FaRegEyeSlash } from "react-icons/fa6";
import { FaRegEdit } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import * as getServices from "../../services/getServices";
import * as updateServices from "../../services/updateServices";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import * as uploadService from "../../services/uploadServices";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import { SampleCourseCSV } from "../../constant/files"
import ViewCourseDetail from "./viewCourse";
import EditCourseDetails from "./editCourse";
import { RiDeleteBin6Line } from "react-icons/ri";

const { Search } = Input
export default function MyCourses() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const vendorData = useSelector((state) => state.user.userData);
    const categories = useSelector((state) => state.user.categoriesData);
    const [courses, setCourses] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
    const [filterData, setFilterData] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(5);
    const [classes, setClasses] = useState([]);
    const [loader, setLoader] = useState(false);
    const [bulkuploadfile, setbulkuploadFile] = useState(null);
    const [hasTyped, setHasTyped] = useState(false);
    const [courseDetails, setCourseDetails] = useState()
    const [popoverVisible, setPopoverVisible] = useState(false);
    const [searchString, setSearchString] = useState('')
    const [filteredCourses, setFilteredCourses] = useState([]);
    const [selectedDates, setSelectedDates] = useState([]);
    const [datePickerValue, setDatePickerValue] = useState([]);
    const [pricingCategory, setPricingCategory] = useState([])
    const [totalItems, setTotalItems] = useState(0)

    const sortedCourses = (() => {
        if (filterData?.length > 0) {
            return filterData?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        } else if (filteredCourses?.length > 0) {
            return filteredCourses.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        } else {
            return courses?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        }
    })();

    const generatePageSizeOptions = (total) => {
        const options = [];
        const maxPageSize = Math.min(total, 100);


        for (let i = 5; i <= maxPageSize; i += 5) {
            options.push(i);
        }
        if (total > 5 && !options.includes(10)) {
            options.push(10);
        }

        if (total > 10 && !options.includes(15)) {
            options.push(15);
        }
        return options.sort((a, b) => a - b);
    };

    const pageSizeOptions = generatePageSizeOptions(totalItems);

    const handlePageSizeChange = (current, size) => {
        setPageSize(size);
        setCurrentPage(1);
    };
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize, totalItems);
    const displayedItems = sortedCourses?.slice(startIndex, endIndex);

    const handleChangePage = (page) => {
        setCourseDetails(null)
        setCurrentPage(page);
    };

    const showModal = (record) => {
        setPopoverVisible(false)
        setCourseDetails(null)
        setCourseDetails(record)
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setPopoverVisible(false)
        setCourseDetails(null)
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setPopoverVisible(false)
        setCourseDetails(null)
        setIsModalOpen(false);
    };
    const handleupdateCourseCancel = () => {
        setPopoverVisible(false)
        setCourseDetails(null)
        setIsUpdateModalOpen(false);
    }

    const getCourse = async (currentPage) => {
        setLoader(true);
        let queryString;
        if (currentPage === -1) {
            queryString = `?per_page=${currentPage}`;
        } else {
            queryString = `?per_page=${pageSize}&page=${currentPage}`
        }
        dispatch(
            getServices?.getCourseByVendorID(queryString))
            .then((response) => {
                if (currentPage === -1) {
                    let data = transformData(response?.data);
                    if (data) {
                        exportToExcel(data, 'Course');
                    }
                } else {
                    setCourses(response?.data);
                    setFilteredCourses(response?.data)
                    setTotalItems(response?.meta?.total)
                }
                setLoader(false);
            }).catch((err) => {
                setLoader(false);
            });
    }

    useEffect(() => {
        getCourse(currentPage)
    }, [pageSize, currentPage])

    useEffect(() => {
        setLoader(true);
        dispatch(getServices.getClasses())
            .then((response) => {
                setClasses(response?.classes);
            })
            .catch((err) => {
                // console.log({ err });
            });

        dispatch(getServices.getPricingCategory()).then((response) => {
            setPricingCategory(response?.pricing_categories);
            setLoader(false)
        }).catch((err) => {
            console.error(err);
            setLoader(false);
        })

    }, [vendorData?.vendor?.id]);

    const deleteCourse = (record) => {
        Swal.fire({
            title: "Delete Item",
            text: "Are you sure you want to delete?",
            icon: "info",
            showCancelButton: true,
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "No, keep it",
        })
            .then((result) => {
                if (result.isConfirmed) {
                    // if confirm to delete course  then run api 
                    dispatch(updateServices?.deleteCourse(record?.id))
                        .then((response) => {
                            Swal.fire(
                                " Deleted Successful!",
                                "Item deleted successfully!",
                                "success"
                            ).then((result) => {
                                // call get all course function after deletion of course 
                                getCourse(currentPage)

                            });
                        })
                        .catch((err) => { });
                }

            })
            .catch((error) => {
                Swal.fire("Failed", "Unable to perform the action.", "error");
            });

    };

    const editRecord = (record) => {
        setCourseDetails(record)
        setIsUpdateModalOpen(true)
    };

    const cloneRecord = (record) => {
        const course_id = record?.id

        dispatch(updateServices?.cloneCourse({ course_id }))
            .then((response) => {
                Swal.fire({
                    title: response.message,
                    icon: "success",
                })
                    .then((result) => {
                        if (result.isConfirmed) {
                            getCourse(currentPage)
                        }

                    });


            })
    }

    const convertCreatedData = (dateStr) => {
        const date = new Date(dateStr);

        const monthNames = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ];

        const year = date?.getUTCFullYear();
        const month = monthNames[date?.getUTCMonth()];
        const day = date?.getUTCDate();

        return `${month} ${day}, ${year}`;
    };

    const renderContent = (record) => (
        <div className="view-edit-delete-container">
            <p className="view">
                <IoEyeOutline
                    onClick={() => showModal(record)}
                    color="#106AB3"
                    className="view-icon"
                />
            </p>
            <p className="edit">
                <FaRegEdit
                    onClick={() => editRecord(record)}
                    color="#106AB3"
                    className="edit-icon"
                />
            </p>
            <p className="edit">
                <LiaClone
                    onClick={() => cloneRecord(record)}
                    color="#106AB3"
                    className="edit-icon"
                />
            </p>
            <p className="delete">
                <RiDeleteBin6Line
                    onClick={() => deleteCourse(record)}
                    color="#DA0734"
                    className="delete-icon"
                />
            </p>
        </div>
    );

    const Content = (record) => (
        <div>
            <p>{record ? `Content for ${record.rejection_reason}` : "Content"}</p>
        </div>
    );

    const columns = [
        {
            title: "S.No.",
            dataIndex: 'serial',
            render: (_, record, index) => (currentPage - 1) * pageSize + index + 1,
        },
        {
            title: "Title",
            dataIndex: "title",
            key: "title",
            width: "16%",
            render: (text, record) => (
                <div className="course-title-container">
                    <p className="course-title">{record?.title}</p>
                </div>
            ),
        },
        {
            title: "Category",
            dataIndex: "category",
            key: "category",
            render: (text, record) => (
                <p className="course-category">{record?.parent_category_name}</p>
            ),
        },
        {
            title: "Sub-Category",
            dataIndex: "subCategory",
            key: "subCategory",
            render: (text, record) => (
                <>
                    <p className="course-subCategory">
                        {record?.sub_category_name}
                    </p>
                </>
            ),
        },
        {
            title: "Child-Category",
            dataIndex: "ChildCategory",
            key: "ChildCategory",
            render: (text, record) => (
                <>
                    <p className="course-subCategory">
                        {record?.child_category_name}
                    </p>
                </>
            ),
        },
        {
            title: "Expired On",
            dataIndex: "expireOn",
            key: "expireOn",
            render: (text, record) => (
                <p className="course-expireOn">{record?.course_end}</p>
            ),
        },
        {
            title: "Visiblity",
            dataIndex: "visiblity",
            key: "visiblity",
            render: (text, record) => (
                <>
                    <div className="course-visibility-container">
                        {record?.visibility === "public" ? (
                            <>
                                <p className="public-icon" style={{ margin: "0" }}>
                                    <IoEyeOutline className="eye-icon" />
                                </p>
                                <p className="course-visiblity">Public</p>
                            </>
                        ) : (
                            <>
                                <p className="hidden-icon">
                                    <FaRegEyeSlash className="closed-eye-icon" />
                                </p>
                                <p className="course-visiblity">Private</p>
                            </>
                        )}
                    </div>
                </>
            ),
        },
        {
            title: "Created On",
            dataIndex: "createdOn",
            key: "createdOn",
            render: (text, record) => (
                <p className="course-createdOn">
                    {convertCreatedData(record?.created_at)}
                </p>
            ),
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (text, record) => (
                <div className="course-status-container">
                    {record?.status === "approved" ? (
                        <p className="approved">{record?.status}</p>
                    ) : (
                        <>
                            {record?.status === "rejected" ? (
                                <>
                                    <Popover
                                        content={Content(record)}
                                        title=""
                                        placement="bottom"
                                        className="popover-reject-reason"
                                    >
                                        <p style={{ cursor: "pointer" }} className="rejected">
                                            {record?.status}
                                        </p>
                                    </Popover>
                                </>
                            ) : (
                                <p className="pending">{record?.status}</p>
                            )}
                        </>
                    )}
                    <p className="dot-menu">
                        <Popover
                            content={renderContent(record)}
                            title="Action"
                            trigger="click"
                            placement="bottomLeft"
                        >
                            <HiOutlineDotsVertical
                                style={{ cursor: "pointer" }}
                                className="line-dot-icon"
                            />
                        </Popover>
                    </p>
                </div>
            ),
        },
    ];

    const DateFormatter = (date) => {
        const inputDate = date;

        const dateObj = new Date(inputDate);

        const monthNames = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ];

        const day = dateObj?.getDate();
        const month = monthNames[dateObj?.getMonth()];
        const year = dateObj.getFullYear();

        const formatDate = `${day} ${month} ${year}`;
        return formatDate;
    };

    const ClassSize = (classsize) => {
        const classS = classes?.find(
            (classess) => parseInt(classess?.id) === parseInt(classsize)
        );
        return classS !== undefined ? classS : classsize;
    };

    const exportToExcel = (data, fileName) => {
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        const excelBuffer = XLSX.write(workbook, {
            bookType: "xlsx",
            type: "array",
        });
        const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
        saveAs(blob, `${fileName}.xlsx`);
    };

    const transformData = (data) => {


        return data.map((item, index) => {
            const faqData = Array.isArray(item?.vendor_faq) && item.vendor_faq?.length > 0 && item.vendor_faq?.length !== "null" && item.vendor_faq?.length !== null
                ? item.vendor_faq.map(faqItem => `Q: ${faqItem.question}, A: ${faqItem.answer}`).join('; ')
                : '';
            return {
                "S.NO": index + 1,
                "Course Name": item.title,
                Category: item?.category?.name,
                "Sub Category": item?.sub_category_name,

                "Class Level":
                    ClassSize(item?.class_level)?.name !== "undefined" && ClassSize(item?.class_level)?.name !== "null"
                        ? ClassSize(item?.class_level)?.name
                        : ClassSize(item?.class_level),
                "Class Size":
                    ClassSize(item?.class_level)?.max_size !== undefined && ClassSize(item?.class_level)?.max_size !== null &&
                        ClassSize(item?.class_level)?.min_size !== 'undefined' && ClassSize(item?.class_level)?.min_size !== 'null'
                        ? `${ClassSize(item?.class_level)?.min_size}-${ClassSize(item?.class_level)?.max_size
                        }`
                        : item.class_size,
                "Class Name": item.class_name,
                "About Course": item?.about_course !== null && item?.about_course !== 'null' ? item.about_course : '',
                "Course Fee": item.fee !== 0 ? item?.fee : 'Free',
                "Special Instruction": item.special_instruction && item.special_instruction !== null && item.special_instruction !== 'undefined' && item.special_instruction !== 'null' ? item.special_instruction : '',
                "Start On": item?.course_start ? DateFormatter(item.course_start) : '',
                "Expire On": item?.course_end ? DateFormatter(item.course_end) : '',

                Status: item.status,
                Visibility: item.visibility !== null ? item.visibility : '',
                "Instructor Name": item.instructor_name !== 'null' && item.instructor_name !== null ? item.instructor_name : '',
                "Work Experience": item.work_experience !== 'null' && item?.work_experience !== null && item?.work_experience !== "NaN" ? `${item.work_experience} years` : '',
                "About Instructor": item?.about !== null && item?.about !== 'null' ? item?.about : '',
                "FAQ's": faqData,
            };
        });

    };

    const onFilter = (date, dateString) => {
        setSelectedDates(dateString)
        setDatePickerValue(date)
    }
    const handleFilterDate = () => {
        if (selectedDates.length == 0 && selectedDates !== null) {
            Swal.fire({
                title: "Please selected date for filter item",
                icon: "info",
            })
        } else {
            const [startDate, endDate] = selectedDates

            // Create URLSearchParams for query parameters
            const params = new URLSearchParams({
                start_date: startDate,
                end_date: endDate,
            });
            const quesryString = `?${params.toString()}`;
            dispatch(uploadService.getCoursesByDate(quesryString)).then((response) => {
                if (response?.data?.length > 0) {
                    setFilterData(response?.data)
                    setTotalItems(response?.meta?.total)
                } else {
                    Swal.fire({
                        title: "There is no data for selected date",
                        icon: "error",
                    }).then((result) => {
                        if (result.isConfirmed) {
                            setSelectedDates([])
                        }
                    })
                }

            })
                .catch(() => {
                    Swal.fire({
                        title: "No item found for selected date",
                        icon: "error",
                    })
                })
        }
    }

    const handleReset = () => {
        setSelectedDates([])
        setSearchString('');
        setFilterData([])
        setDatePickerValue([])
        // setFilteredCourses(courses); 

    }

    const beforeUpload = (file) => {
        const isCsvOrXls = file.type === 'text/csv' || file.type === 'application/vnd.ms-excel';
        if (!isCsvOrXls) {
            Swal.fire({
                title: "Invalid File Format",
                text: "Please upload a valid CSV or XLS file.",
                icon: "error",
            });
        }
        return isCsvOrXls;
    };

    const handleCourseCSVUpload = () => {
        if (bulkuploadfile) {
            const formData = new FormData();
            formData.append("csv_file", bulkuploadfile);
            dispatch(uploadService?.uploadCoursesCSV(formData)).then((response) => {
                Swal.fire({
                    title: response.message,
                    icon: "success",
                })
            })
                .catch(() => {
                    Swal.fire({
                        title: "Failed to upload item",
                        icon: "error",
                    })
                })
        } else {
            Swal.fire({
                title: "Please select a file",
                icon: "error",
            })
        }
        getCourse(currentPage)
        setbulkuploadFile(null)

    }

    const applyFilters = (value) => {

        if (value === '') {
            if (hasTyped) {
                setFilteredCourses(courses);
                setHasTyped(false);
                return;
            } else {
                Swal.fire({
                    title: "Please write anything for search",
                    icon: "info",
                    allowEnterKey: false
                });
                return;
            }
        }
        setHasTyped(true);

        let filteredData = courses.filter((searchdata) =>
            searchdata.title.toLowerCase().includes(value.toLowerCase())
        );

        if (filteredData.length === 0) {
            Swal.fire({
                title: "There is no such item",
                icon: "error",
                allowEnterKey: false
            });
        } else {
            setFilteredCourses(filteredData);
            // setLoader(false)
        }
    };

    const handleDownloadClick = () => {
        getCourse(-1);
    };
    console.log(loader, "loader")
    return (
        <>
            <Row style={{ position: 'relative' }}>
                <Col xl={4} className="d-xl-block d-md-none">
                    <Sidebar />
                </Col>
                {loader ? (
                    <div className="loaderBackdrop">
                        <div className="loader">
                            <Spin indicator={<LoadingOutlined style={{ color: "#fff" }} spin />} />
                        </div>
                    </div>
                ) : (
                    <>
                        <Col xl={20} md={24}>
                            <div className="mainContent my-courses">
                                <div className="my-courses-container">
                                    <div className="download-courses-container">
                                        <p className="my-course-heading">My items</p>

                                    </div>
                                    <div className="course-filter-add-item">
                                        <div className="courses-head-filter">


                                            <DatePicker.RangePicker
                                                onChange={onFilter}
                                                value={datePickerValue}
                                            />
                                            <Button onClick={handleFilterDate}>Filter</Button>
                                            <Button onClick={handleReset} type="primary">Reset</Button>
                                        </div>
                                        <Button
                                            onClick={() => navigate("/vendor/add-new-course", {
                                                state: {
                                                    pricingCategory
                                                }
                                            })}
                                            className="add-new-course-button"
                                        >
                                            Add New Item
                                        </Button>
                                    </div>

                                    <div className="courses-head-container">
                                        <div className="sample-course-container">
                                            <Button className="download-sample-button">
                                                <a className="download-file-link" href={SampleCourseCSV} download="Sample Course">Download Sample</a>
                                            </Button>
                                            <div className="upload-file-container">
                                                <Upload onChange={(e) => setbulkuploadFile(e?.file?.originFileObj)} className="file-upload" beforeUpload={beforeUpload} showUploadList={false}>
                                                    <Button className="select-file-button">{bulkuploadfile ? bulkuploadfile?.name : "Select File"}</Button>
                                                </Upload>
                                                <Button onClick={handleCourseCSVUpload} className="upload-file-button">Upload File</Button>
                                            </div>
                                        </div>
                                        <Button
                                            className="download-courses-button"
                                            // onClick={() => exportToExcel(transformData(-1), "Courses")}
                                            onClick={() => handleDownloadClick()}
                                        >
                                            {/* {loader ? (
                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
                                            <Spin indicator={<LoadingOutlined style={{ color: "#fff" }} spin />} />
                                        </div>
                                    ) : ( */}
                                            "Download item in xlsx/csv"
                                            {/* )} */}
                                        </Button>
                                    </div>
                                    <div>

                                        <Space className="space">
                                            <Search
                                                placeholder="input search text"
                                                onSearch={applyFilters}
                                                // enterButton

                                                allowClear


                                            />
                                        </Space>

                                    </div>
                                    <div className="my-course-table-container table-responsive">

                                        {/* {
                                    loader ? (
                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
                                            <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
                                        </div>
                                    ) : ( */}
                                        <>

                                            {
                                                sortedCourses?.length > 0 ? (
                                                    <>
                                                        <Table
                                                            className="my-course-table"
                                                            columns={columns}
                                                            dataSource={sortedCourses}
                                                            pagination={false}
                                                            loading={{
                                                                spinning: loader,
                                                                indicator: <Spin indicator={<LoadingOutlined spin />} />,
                                                            }}
                                                        />
                                                        <Pagination
                                                            className="mt-4"
                                                            current={currentPage}
                                                            pageSize={pageSize}
                                                            total={totalItems}
                                                            onChange={handleChangePage}
                                                            // showSizeChanger={false}
                                                            onShowSizeChange={handlePageSizeChange}
                                                            showSizeChanger
                                                            pageSizeOptions={pageSizeOptions}
                                                        />
                                                    </>
                                                ) : "No Data"
                                            }

                                        </>
                                        {/* // )} */}

                                    </div>
                                </div>
                            </div>
                        </Col>
                    </>
                )}

            </Row>

            <Modal
                className="view-item-detail-model"
                title="View item Detail"
                open={isModalOpen}
                onCancel={handleCancel}
                footer={[
                    <Button key="cancel" onClick={handleCancel}>
                        Cancel
                    </Button>
                ]}
                style={{ marginBottom: "150px" }}
            >
                <div
                    style={{ background: "#fff" }}
                    className="form-container form-container-view"
                >
                    <ViewCourseDetail courseDetails={courseDetails} classes={classes} pricingCategory={pricingCategory} />
                </div>
            </Modal>
            <Modal
                className="vendor-edit-item-model"
                title="Edit item"
                open={isUpdateModalOpen}
                onCancel={handleupdateCourseCancel}
                footer={[
                    <Button key="cancel" onClick={handleupdateCourseCancel}>
                        Cancel
                    </Button>
                ]}
                style={{ marginBottom: "150px" }}
            >
                <div
                    style={{ background: "#fff" }}
                    className="form-container form-container-view"
                >
                    <EditCourseDetails categories={categories} setCourseDetails={setCourseDetails} courseDetails={courseDetails} classes={classes} setIsUpdateModalOpen={setIsUpdateModalOpen} getCourse={getCourse} pricingCategory={pricingCategory} />
                </div>
            </Modal>
        </>
    );
}

import React, { useState, useEffect } from "react";
import Sidebar from "../../../components/Sidebar/sidebar";
import { Button, Tabs, Input, Card, Col, Row, Image, Spin, Modal, Form, Select, Tooltip, Pagination } from "antd";
import { featureImg2 } from "../../../constant/images";
import { BsFillBarChartFill } from "react-icons/bs";
import { RiGraduationCapFill } from "react-icons/ri";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FiEye } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import * as getServices from "../../../services/getServices";
import { LoadingOutlined } from '@ant-design/icons';
import * as updateServices from "../../../services/updateServices"
import Swal from "sweetalert2"
import { useNavigate } from "react-router";
import { MdOutlinePermContactCalendar } from "react-icons/md";
import * as uploadServices from "../../../services/uploadServices";
import { CiUser } from "react-icons/ci";
import { CiMail } from "react-icons/ci";
import { BsTelephone } from "react-icons/bs";
import { DEMO_IMAGE_URL } from "../../../Helper"
import { MdCancel } from "react-icons/md";



export default function MyCourses() {
    const userData = useSelector((state) => state.user.userData);
    const dispatch = useDispatch();
    const { TextArea } = Input;
    const [courses, setCourses] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedCourse, setSelectedCourse] = useState()
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(5);
    const [totalCourses, setTotalCourses] = useState(0)
    const [form] = Form.useForm();

    const [loader, setLoader] = useState(false);
    const navigate = useNavigate()

    const fetchUserCourses = (currentPage) => {
        setLoader(true)
        const queryString = `?my_course=true&per_page=${pageSize}&page=${currentPage}`
        dispatch(getServices?.getUserCourses(queryString)).then((response) => {
            setTotalCourses(response?.meta?.total)
            setLoader(false)
            setCourses(response?.data)
        }).catch((error) => {
            setLoader(false)
            console.error(error)
        })
    }

    useEffect(() => {
        try {
            fetchUserCourses(currentPage)
        } catch (err) {
            console.error(err)
        }
    }, [userData, pageSize, currentPage]);


    const onFinish = (values) => {
        const formData = new FormData();
        formData.append("name", values.fullName);
        formData.append("email", values.email);
        formData.append("phone", values.phone);
        formData.append("typeOfEnquiry", values.typeOfEnquiry);
        formData.append("message", values.message);
        formData.append("course_id", selectedCourse?.id);
        formData.append("vendor_id", selectedCourse?.vendor?.id);

        dispatch(uploadServices.contactAdmin(formData))
            .then((res) => {
                setModalOpen(false)
                if (res.message) {
                    Swal.fire({
                        title: res.message,
                        icon: "success",
                    }).then((result) => {
                        if (result.isConfirmed) {
                            form.resetFields();
                        }
                    })
                }
            })
            .catch(() => {
                Swal.fire({
                    title: "please fill  all the fields",
                    icon: "error",
                })
                    .then((result) => {
                        if (result.isConfirmed) {
                            form.resetFields();
                        }
                    })
            })
    }
    const onFinishFailed = () => { };

    const handleBuyCourse = () => {
        navigate(`/course-listing`, { state: { querystring: null } });
    }

    const handleCancelCourse = (data) => {

        Swal.fire({
            title: "Cancel item",
            text: "Are you sure you want to cancel?",
            icon: "info",
            showCancelButton: true,
            input: "textarea",
            confirmButtonText: "Yes, cancel it!",
            cancelButtonText: "No, keep it",
            inputPlaceholder: "Enter the reason for cancellation",
        })

            .then((result) => {
                if (result.isConfirmed) {
                    const formData = new FormData()
                    formData.append("order_id", data?.order_id)
                    formData.append("payment_id", data?.payment_id)
                    formData.append("order_item_id", data?.order_item_id)
                    formData.append("reason", result?.value)

                    dispatch(updateServices.cancelCourseUser(formData))
                        .then((response) => {
                            Swal.fire("Success", "Course has been requested for cancellation by the Admin.", "success");
                        })
                        .then(() => {
                            fetchUserCourses(currentPage)
                        })
                        .catch((error) => {
                            Swal.fire("Failed", "Unable to cancel item.", "error");
                        });
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    Swal.fire("Cancelled", "Your item is safe :)", "error");
                }
            })
            .catch((error) => {
                Swal.fire("Failed", "Unable to perform the action.", "error");
            });
    }


    const handleContactCourse = (data) => {
        setSelectedCourse(data)
        setModalOpen(true)
    }

    const handleCourseData = (values) => {

        navigate(`/course/${values?.id}/${values?.title}`, {
            state: { course_id: values?.id },
        });
    };

    const onchange = (page) => {
        setCurrentPage(page);
    };

    const generatePageSizeOptions = (total) => {
        const options = [];
        const maxPageSize = Math.min(total, 100);

        for (let i = 5; i <= maxPageSize; i += 5) {
            options.push(i);
        }
        if (total > 5 && !options.includes(10)) {
            options.push(10);
        }

        if (total > 10 && !options.includes(15)) {
            options.push(15);
        }
        return options.sort((a, b) => a - b);
    };

    const pageSizeOptions = generatePageSizeOptions(totalCourses);

    const onPageSizeChange = (current, size) => {
        setPageSize(size);
        setCurrentPage(1);
    }



    const items = [
        {
            key: "1",
            label: "All Items",
            children: (
                <div className="tab-content-container">
                    <Row className="tab-content-row">
                        {loader ? (
                            <div className="loaderBackdrop">
                                <div className="loader">
                                    <Spin indicator={<LoadingOutlined style={{ color: "#fff" }} spin />} />
                                </div>
                            </div>
                        ) : (
                                courses?.length > 0 ? (
                                    courses && courses.map((course) => {
                                        return (
                                            <Col className="tab-content-col" md={24} key={course?.id}>
                                                <Card className="tab-content-card" hoverable>
                                                    <Row className="card-row">
                                                        <Col className="card-col-one" md={6}>
                                                            <Image preview={false} src={course?.image ? `${DEMO_IMAGE_URL}${course?.image}` : featureImg2} />
                                                        </Col>
                                                        <Col className="card-col-two" md={13}>
                                                            <p className="course-name">{course?.parent_category_name}</p>
                                                            <div className="title-purchase-status">
                                                                <p className="sub-heading">{course?.title}</p>
                                                                {course?.purchased_status === "requested" ? (
                                                                    <Tooltip title="Course has been request for cancellation by the Admin." >
                                                                        <p className="purchase-status-pending">pending</p>
                                                                    </Tooltip>
                                                                ) : (
                                                                    course?.purchased_status === "approved" ? (
                                                                        <Tooltip title="Course has been cancelled by the Admin. " >
                                                                            <p className="purchase-status-rejected">cancelled</p>
                                                                        </Tooltip>
                                                                    ) : (
                                                                        <></>
                                                                    )
                                                                )}

                                                            </div>
                                                            <p className="description-course">
                                                                {course?.about_course}
                                                            </p>

                                                            {/* <Row className="students-level-lesson-row">
                                                            <Col className="students-with-icon" md={8}>
                                                                <RiGraduationCapFill className="graguation-cap-icon" />
                                                                <p className="no-of-students">{course?.course_count} Students</p>
                                                            </Col>
                                                            <Col className="levels-with-icon" md={8}>
                                                                <BsFillBarChartFill className="bar-chart-icon" />
                                                                <p className="all-levels">All levels</p>
                                                            </Col>
                                                        </Row> */}
                                                        </Col>
                                                        <Col className="card-col-three" md={5}>
                                                            <Tooltip title="Cancel" >
                                                                <span className="delete-span" onClick={(e) => handleCancelCourse(course)}>
                                                                    <MdCancel className="delete-icon" />
                                                                </span>
                                                            </Tooltip>
                                                            <Tooltip title="View" >
                                                                <span onClick={() => handleCourseData(course)} className="view-span">
                                                                    <FiEye className="view-icon" />
                                                                </span>
                                                            </Tooltip>
                                                            <Tooltip title="Contact" >
                                                                <span onClick={() => handleContactCourse(course)} className="contact-span">
                                                                    <MdOutlinePermContactCalendar className="contact-icon" />
                                                                </span>
                                                            </Tooltip>
                                                        </Col>
                                                    </Row>
                                                </Card>
                                            </Col>

                                        )
                                    })
                                ) : (
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }} className="null-value-container">
                                        <p className="no-data-text">No Data</p>
                                    </div>
                                )
                        )}
                       
                           
                       
                        <Pagination
                            className="pagination mt-4"
                            current={currentPage}
                            onChange={onchange}
                            total={totalCourses}
                            pageSize={pageSize}
                            onShowSizeChange={onPageSizeChange}
                            showSizeChanger
                            pageSizeOptions={pageSizeOptions}
                        />
                    </Row>
                </div>
            ),
        },
    ];
    return (
        <>
            <Row>
                <Col xl={4} className="d-xl-block d-md-none">
                    <Sidebar />
                </Col>
                <Col xl={20} md={24}>
                    <div className="mainContent my-courses">
                        <div className="head-container">
                            <div className="heading-div">
                                <p className="my-course-text">My Items</p>
                            </div>
                            <div className="buy-course-container">
                                <Button className="buy-course-button" type="primary" onClick={handleBuyCourse}>Buy Items</Button>
                            </div>
                            <div className="add-new-course-container">
                            </div>
                        </div>
                        <div className="tabs-container">
                            <Tabs
                                className="my-courses-tabs"
                                defaultActiveKey="1"
                                items={items}
                            />
                        </div>
                    </div>
                </Col>
            </Row>
            <Modal
                className="contact-course-modal"
                title={selectedCourse?.title}
                centered
                open={modalOpen}
                onOk={() => setModalOpen(false)}
                onCancel={() => setModalOpen(false)}
            >
                <Form
                    name="basic"
                    className="contact-course-form"
                    layout="vertical"
                    initialValues={{
                        fullName: userData?.user?.name,
                        email: userData?.user?.email,
                        phone: userData?.user?.phone
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    form={form}
                >
                    <Row>
                        <Col md={12} className="custom-padding">
                            <Form.Item
                                className="form-input-class"
                                name="fullName"
                                label="Full Name"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please enter full name.",
                                    },
                                ]}
                            >
                                <Input
                                    className="input-box-login"
                                    placeholder="Enter your name here"
                                    prefix={<CiUser />}
                                />
                            </Form.Item>
                        </Col>
                        <Col md={12} className="custom-padding">
                            <Form.Item
                                className="form-input-class"
                                label="Email"
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        message: "Email is not valid",
                                        type: "email",
                                    },
                                ]}
                            >
                                <Input
                                    className="input-box-login"
                                    placeholder="Johndoe@gmail.com"
                                    prefix={<CiMail />}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={12} className="custom-padding">
                            <Form.Item
                                className="form-input-class"
                                name="phone"
                                label="Phone Number"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please enter phone number.",
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            const initialPhoneNumber = getFieldValue('phone');

                                            if (initialPhoneNumber === value) {
                                                return Promise.resolve();
                                            }
                                            if (value && value?.length === 10) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(
                                                new Error("Phone number must be exactly 10 digits.")
                                            );
                                        },
                                    }),
                                ]}
                            >
                                <Input
                                    className="input-box-login"
                                    placeholder="please input phone number"
                                    prefix={<BsTelephone />}
                                    onKeyPress={(e) => {
                                        if (!/[0-9]/.test(e.key)) {
                                            e.preventDefault();
                                        }
                                    }}
                                    onPaste={(e) => {
                                        const paste = (e.clipboardData || window.clipboardData).getData("text");
                                        if (!/^[0-9]+$/.test(paste)) {
                                            e.preventDefault();
                                        }
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col md={12} className="custom-padding">
                            <Form.Item
                                className="form-input-class type-of-enquiry"
                                name="typeOfEnquiry"
                                label="Type Of Enquiry"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please select Enquiry.",
                                    },
                                ]}
                            >
                                <Select
                                    className="input-box-login type-of-enquiry"
                                    placeholder="Select type of enquiry"
                                >
                                    <Select.Option value="Course Enquiry">Course Enquiry</Select.Option>
                                    <Select.Option value="Payment Enquiry">Payment Enquiry</Select.Option>
                                    <Select.Option value="Other">Other</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={24}>
                            <p
                                style={{
                                    textAlign: "start",
                                    fontFamily: "POPPINS",
                                    padding: "0px 0px 0px 10px",
                                }}
                            >
                                Message
                            </p>
                            <Form.Item
                                className="mb-0"
                                name="message"
                                wrapperCol={{
                                    offset: 8,
                                    span: 24,
                                }}

                            >
                                <TextArea className="message-text-area" style={{ height: "300px" }} rows={4} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Form.Item
                        className="mb-0 text-center"
                        wrapperCol={{
                            offset: 8,
                            span: 24,
                        }}
                    >
                        <Button
                            className="register-button button-register"
                            htmlType="submit"
                        >
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
}
